import { ComposableMap } from 'react-simple-maps'
import styled from 'styled-components'

import { opacityAppear } from '@styles/animations/appearAnimations'

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  max-width: ${p => p.theme.sizes.desktopM + 64}px;
  margin-inline: 32px;
  height: 737px;
  left: 50%;
  transform: translateX(calc(-50% - 32px));
  border-radius: ${p => p.theme.borderRadius.default};
  box-shadow: ${p => p.theme.shadows.modal};
  overflow: hidden;

  & > svg {
    height: inherit;
    width: 100%;
    fill: ${p => p.theme.colors.backgroundBlueContainer};
    stroke: ${p => p.theme.colors.backgroundInput};

    .rsm-line,
    .rsm-marker {
      animation: ${opacityAppear} ${p => p.theme.transitions.default};
    }

    &,
    & > g {
      animation: ${opacityAppear} ${p => p.theme.transitions.default};
    }
  }

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 400px;
    height: 400px;
    background: radial-gradient(
      ellipse at left bottom,
      ${p => p.theme.colors.backgroundGradientBlue} 0%,
      ${p => p.theme.colors.backgroundGradientBlueOpacity} 75%
    );
  }

  ${p => p.theme.mediaQueries.desktopS} {
    height: 687px;

    &:before {
      display: none;
    }
  }

  ${p => p.theme.mediaQueries.mobileTablet} {
    height: 772px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    display: none;
  }
`

export const ComposableMapStyled = styled(ComposableMap)`
  height: inherit;
  width: 100%;
`
